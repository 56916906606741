'use strict';

let $homeGallery = $('.home-gallery, .block-full-gallery');
let $scrollDown = $('.scroll-down');
// CONCIERGE PAGE
let $serviceTitle = $('.concierge h2');

export default class Home {
    constructor() {
        this.homeSlickInit();
    }

    eventListeners() {
        
        $scrollDown.on('click', function() {
            let $sections = $(this).siblings('section');
            let $secondSection = $($sections[1]);
    
            let distance = $secondSection.offset().top - 49;
            $('html,body').animate({
                scrollTop: distance
            }, 600);
            $(this).addClass('hide-button');
        });

        $serviceTitle.on('click', function() {
            let $this = $(this);
            let $service = $this.parent('li');
            $service.toggleClass('reveal');
        });
    }

    homeSlickInit() {
        $homeGallery.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 3500,
            autoplay: true,
            fade: true,
            cssEase: 'ease-in',
            pauseOnHover: false,
            adaptiveHeight: true
        });
    }
}


