'use strict';

var $lifestyleSlick = $('.lifestyle-slick, .product-gallery-slick');

export default class Lifestyle {
    constructor() {
        this.slickInit();
        this.slickChangeHandler();
    }

    slickInit() {
        $lifestyleSlick.slick({
            dots: true,
            infinite: true,
            speed: 750,
            adaptiveHeight: true,
            swipe: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                  breakpoint:950,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
        
    }

    slickChangeHandler() {
        $('.product-gallery-slick').on('afterChange', function(event, slick, currentSlide) {
            var $this = $(this);
            var $siblingCounter = $this.siblings('p.counter');
                $siblingCounter.text(currentSlide + 1);
        });
    }
}








