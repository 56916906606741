'use strict';

let $collectionsSlick = $('.collections-slick');
let $featuredProductsThree = $('.featured-products-slick.three-up');
let $featuredProductsFour = $('.featured-products-slick.four-up');

export default class Designer {
    constructor() {
        this.collectionsSlickInit();
        this.featureSlickInit();
        this.featuredFourSlickinit();

        this.collectionSlickHandler();
    }

    collectionsSlickInit() {
        $collectionsSlick.slick({
            dots: false,
            infinite: true,
            speed: 750,
            adaptiveHeight: true,
            swipe: false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    collectionSlickHandler() {
        $collectionsSlick.on('afterChange', function(event, slick, currentSlide) {
            let $this = $(this);
            let $siblingCounter = $this.siblings('p.counter');
                $siblingCounter.text(currentSlide + 1);
        });
    }

    featureSlickInit() {
        $featuredProductsThree.slick({
            dots: false,
            infinite: true,
            speed: 750,
            adaptiveHeight: true,
            slidesToShow: 3,
            swipe: false,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    featuredFourSlickinit() {
        // TO-DO: Remove Arrows on all carousels for products
        $featuredProductsFour.slick({
            dots: true,
            infinite: true,
            speed: 750,
            adaptiveHeight: true,
            swipe:false,
            slidesToShow: 4,
            slidesToScroll: 4,
            responsive: [
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }
}














