'use strict';

import Login from './login';

let _Login = new Login();

// NAV MENU ITEMS
let $avatar = $('.icon-avatar');
let $burgerIcon = $('.hamburger-container');
let $profile = $('.profile-container');
let $bag = $('.bag');
let $mobileMenu = $('.mobile-menu');
let $desktopNavItem = $('.desktop-nav .nav-item');
let $mobileNavItem = $('.mobile-nav .nav-item');
let $mobileSubNavItem = $('.mobile-nav .sub-nav-item');
let $filterModule = $('aside.filter-module');
let $collectionsMenu = $('aside.collections-menu');
let $body = $('body');

let $mainNav = $('.main-nav');


export default class Main {
	constructor() {
		this.checkScrollPos();
	}

	/**
	 * open data-target in new tabs
	 * 
	 */
	popupWindow(){
		$('a[data-popup]').on('click', function(event){
			event.preventDefault();
			window.open($(this)[0].href);
		});
	}

	getQueryVariable(variable){
		let queryStr = window.location.search.substring(1);
		let queries = queryStr.split('&');
		for(let query of queries) {
			let pair = query.split('=');
			if(pair[0] === variable){
				return pair[1];
			}
		}
		return(false);
	}

	checkScrollPos() {
		setInterval(function() {
			// let $documentHeight = $(document).height();
			let $windowHeight   = $(window).height();
			let $scrollPosition = $(document).scrollTop();
		
			if ($scrollPosition >= ($windowHeight / 3)) {
				$('.scroll-down').addClass('hide-button');
			} else {
				$('.scroll-down').removeClass('hide-button');
			}
		}, 500);
	}
	
	checkForSignUp() {
		let signUp = this.getQueryVariable('signUp');
		if (signUp) {
			_Login.requestMembership();
		}
	}
	
	disableHover() {
		if(! $body.hasClass('disable-hover')){
			$body.addClass('disable-hover');
		}
		setTimeout(() => {
			$body.removeClass('disable-hover');
		}, 250);
	}

	/**
	 * Listens for scroll event and calls
	 * methods relying on scroll
	 * 
	 */
	onScroll() {
		$(window).on('scroll', () => {
			this.disableHover();
		});
	}

	onResize() {
		// $(window).on('resize', () => {
		// 	if(window.innerWidth >= 1023) {
		// 		$('body').removeClass('lock');
		// 	}
		// });
	}

	toggleMenu() {
		$filterModule.removeClass('reveal');
		$collectionsMenu.removeClass('reveal');
		$mainNav.toggleClass('nav-open');
		$mobileMenu.toggleClass('menu-open');
		if ($profile.hasClass('show-profile')) {
			$profile.removeClass('show-profile');
		}
		if($mobileMenu.hasClass('menu-open') || $filterModule.hasClass('reveal')) {
			$body.addClass('lock');
		} else {
			$body.removeClass('lock');
		}
	}

	eventListeners() {

		this.onScroll();
		this.onResize();

		$avatar.on('click', () => {
			let userCookie = Cookies.get('currentUser');
			if(userCookie === 'true') {
				this.toggleMenu();
			} else {
				_Login.showModal();
			}
		});

		$burgerIcon.on('click', () => {
			this.toggleMenu();
		});

		$bag.on('click', function() {
			$profile.toggleClass('show-profile');
			if ($mobileMenu.hasClass('menu-open')) {
				$mobileMenu.removeClass('menu-open');
				// $burgerIcon.removeClass('hamburger-open');
			}
		});
		$desktopNavItem.on('click', function() {
			let $this = $(this);

			// Handle clothing menu
			if ($this.hasClass('clothing')) {
				$filterModule.toggleClass('reveal');
				$collectionsMenu.removeClass('reveal');
				$body.toggleClass('lock');
			}

			if ($this.hasClass('collection-toggle')) {
				$filterModule.removeClass('reveal');
				$collectionsMenu.toggleClass('reveal');
				$body.toggleClass('lock');
			}
		});
		$mobileNavItem.on('click', function() {
			let $this = $(this);

			// Handle clothing menu
			if ($this.hasClass('clothing')) {
				$filterModule.toggleClass('reveal');
				$mobileMenu.removeClass('menu-open');
				// $body.toggleClass('lock');
				$mainNav.removeClass('nav-open');
			}
			let $siblingNav = $this.siblings('.sub-nav');
			$siblingNav.toggleClass('expand');
		});
		$mobileSubNavItem.on('click', function() {
			let $this = $(this);
			let $siblingNav = $this.siblings('.child-nav');
			$siblingNav.toggleClass('expand');
		});

		// KlaviyoSubscribe.attachToForms('#email_signup', {
		// 	hide_form_on_success: true
		// });
	}
	
}




