'use strict';

// ELEMENT VARS
let $loginForm = $('.login');
let $signUpForm = $('.signUp');
let $forgotForm = $('.forgot');
// let $enterContainer = $('.request-container');
let $closeLogin = $('.close-login');
let $requestMembership = $('.requestMembership');
let $forgotPassword = $('.forgotPassword');
let $signUpInputs = $('.signup-block input');
let $signUpBtn = $('.signup-btn');
let $menu = $('.mobile-menu');
let $mainNav = $('.main-nav');

let $signupInputs = $('.signup-name input, .signup-bottom input');
let $loginHiddenInput = '<input class="login-hidden-input" type="hidden" name="action" value="users/login">';

export default class Login {
    constructor() {
        this.state = {
            $modal: $('.login-modal'),
            $page: $('.login-page'),
            $bgOverlay: $('.bg-overlay'),
            $signIn: $('.sign-in')
        };

        this.signupCookieHandler();
    }

    hideModal() {
        let { $page, $bgOverlay } = this.state;
        $page.add($bgOverlay).removeClass('show');
        // this.state.$modal.addClass('hide');
        $forgotForm.addClass('hide');
        $signUpForm.addClass('hide');
        $loginForm.removeClass('hide');
        $('.login').removeClass('fade');
        $('body').removeClass('lock');
    }

    showModal() {
        let { $page, $bgOverlay } = this.state;
        $page.add($bgOverlay).addClass('show');
        $menu.removeClass('menu-open');
        $mainNav.removeClass('nav-open');
        $('body').addClass('lock');
    }
    
    requestMembership() {
        this.state.$modal.removeClass('hide');
        $signUpForm.removeClass('hide');
        $loginForm.addClass('hide');
    }
    
    showSignupForm() {
        let { $modal } = this.state;
        $modal.addClass('signup');
        $('.login-error').addClass('hide');
        $('.login-hidden-input').remove();
        if($('.signup-error').hasClass('multi-error')) {
            $('.login-modal').addClass('multi-error');
            $('.signup-error').removeClass('hide');
        }
        // Add tab indexes to inputs
        this.addTabIndicies(true);
        // activate signup inputs
        $signupInputs.prop('disabled', false);
        setTimeout(() => {
            // timeout is needed to wait until el is back 
            // in view to avoid disrupting animation
            $('.f-name').focus(); 
        }, 800);
    }
    
    hideSignupForm() {
        let { $modal } = this.state;
        $modal.removeClass('signup');
        $('.login-error').removeClass('hide');
        $('.signup-hidden-input').remove();
        $('.login-column').prepend($loginHiddenInput);
        $('.signup-error').addClass('hide');
        $('.login-modal').removeClass('multi-error');
        // remove tab indexes from inputs
        this.addTabIndicies(false);
        // deactivate singup inputs
        $signupInputs.prop('disabled', true);
        $('.email-input').focus();
    }

    addTabIndicies (add = true) {
        $('.f-name').attr('tabindex', add ? '1' : null);
        $('.l-name').attr('tabindex', add ? '2' : null);
        $('.email-input').attr('tabindex', add ? '3' : null);
        $('.zipcode').attr('tabindex', add ? '4' : null);
        $('.password-input').attr('tabindex', add ? '5' : null);
        $('.confirm-password-input').attr('tabindex', add ? '6' : null);
    }

    submitSignupForm() {
        let loginName = $('.email-input').val();
        let password = $('.password-input').val();
        let repassword = $('.confirm-password-input').val();
        let firstName = $('.f-name').val();
        let lastName = $('.l-name').val();
        let zipcode = $('.zipcode').val();
        
        if(password !== repassword) {
            $('.signup-error').removeClass('hide');
            $('.signup-error').text('Passwords are not equal');
            return;
        }
    
        // Make remember dynamic based on checkbox entry
        let data = {
            email: loginName,
            password,
            firstName,
            lastName,
            rememberMeSignUp: 1,
            'fields[zipCode]': zipcode
        };
    
        $.ajax({
            type: 'POST',
            url: '/actions/users/save-user',
            dataType: 'json',
            data: data,
            success: res => {
                if(res.success) {
                    // user created, refresh page
                    window.location.reload();
                } else {
                    $('.signup-error').removeClass('hide').empty();
                    let errors = Object.keys(res.errors);
                    errors = errors.map(err => res.errors[err][0]);
                    if(errors.length > 1) {
                        $('.login-modal').add('.signup-error')
                                         .addClass('multi-error');
                    }
                    errors.forEach(err => {
                        $('.signup-error').addClass('error')
                                          .append(`<li>${err}</li>`);
                    });
                }
            },
            error: (err, err2, err3) => {
                console.log({err, err2, err3});
            }
        });
    }

    signupCookieHandler() {
        // check cookie to display signup modal
        let signupCook = Cookies.getJSON('signup') || null;
        let userCookie = Cookies.get('currentUser');
        if ( 
            signupCook &&
            userCookie === 'false' && 
            signupCook.count >= 6 && 
            document.location.pathname !== '/about-us'
        ) {
            setTimeout(() => {
                this.showModal();
            }, 1500);
            signupCook.count = 0;
            Cookies.set('signup', signupCook);
        }
    }

    /**
     * Event Listeners
     * 
     */
    eventListeners() {
        let { $modal } = this.state;
        
        $('.back-to-login-btn').on('click', () => {
            this.hideSignupForm();
        });

        $signUpBtn.on('click', (e) => {
            if(!$modal.hasClass('signup')) {
                e.preventDefault();
                this.showSignupForm();
            } 
        });

        $('.signin-form').on('submit', (e) => {
            if($modal.hasClass('signup')) {
                e.preventDefault();
                // submit form
                this.submitSignupForm();
            }
        });

        $forgotPassword.on('click', () => {
            $('.login').addClass('fade');
            $('.forgot').removeClass('hide');
        });

        $closeLogin.on('click', () => {
            this.hideModal();
        });

        $requestMembership.on('click', () => {
            this.requestMembership();
        });

        this.state.$signIn.on('click', () => {
            this.showModal();
        });

        $signUpInputs.on('keyup', function() {
            let $this = $(this);
            let $parent = $this.closest('div');
            let value = $this.val();
            if (value.length > 0) {
                $parent.addClass('has-text');
            } else {
                $parent.removeClass('has-text');
            }
        });

        $signUpInputs.on('focus', function() {
            let $this = $(this);
            let $parent = $this.closest('div');
                $parent.addClass('active');
        });

        $signUpInputs.on('blur', function() {
            let $this = $(this);
            let $parent = $this.closest('div');
                $parent.removeClass('active');
        });
    }
}

