'use strict';

import Main from './main';
import Checkout from './checkout';
import CreateProfile from './create-profile';
import Designer from './designer';
import Filter from './filter';
import Home from './home';
import Lifestyle from './lifestyle';
import Login from './login';
import Lookbook from './lookbook';
import Product from './product';
import Profile from './profile';
import Utils from './utils';

new Utils();


let main     = new Main();
let checkout = new Checkout();
let filter   = new Filter();
let profile  = new Profile();
let home     = new Home();
let login    = new Login();
let product  = new Product();
let lookbook = new Lookbook();

// initialize create profile flow
new CreateProfile();
// to initialize slick sliders
new Designer();
new Lifestyle();


function eventListeners() {
    main.eventListeners();
    checkout.eventListeners();
    filter.eventListeners();
    profile.eventListeners();
    home.eventListeners();
    login.eventListeners();
    lookbook.eventListeners();
    product.eventListeners();
}


/**
 * Entry point to main.js
 * 
 */
$(() => {
	main.popupWindow();
	main.checkForSignUp();
    filter.init();

    eventListeners();
});