'use strict';

module.exports = () => {

	var $contactedWay = $('.contacted-way input[type="checkbox"]');
	// var $customerAddress = $('#customer-address');
	var $interest = $('.interests input');

	$(document).ready(function() {

		$contactedWay.on('click',function(){
			if(this.checked ===  true){
			$(this).siblings('.inline').removeClass('hidden');
			}else{
			$(this).siblings('.inline').addClass('hidden');
			}

			
		});
		$interest.on('click',function(){
		if(this.value === 'other'){
			console.log('checked',this.checked);
			if(this.checked === true){
			$('.full-row.other').css('display','block');
			}else{
			$('.full-row.other').css('display','none');
			}
		}
		if(this.value === 'charities'){
			console.log('checked',this.checked);
			if(this.checked === true){
			$('.full-row.charities').css('display','block');
			}else{
			$('.full-row.charities').css('display','none');
			}
		}      

		});

		// $customerAddress.on('keyup',function(){

		//   var formData = {
		//     'address[id]':'8704',
		//     'address[address1]': '19 Hayden Street',
		//     'address[address2]': 'Suite 220',
		//     'address[city]':'Andover',
		//     'address[zipCode]':'01810',
		//   }
		//   $.ajax({
		//       type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
		//       url         : $(this).data('url') +'commerce/customerAddresses/save', // the url where we want to POST
		//       data        : formData, // our data object
		//       success     : function(data){
		//         console.log('data',data)
		//       }
		//   })     

		// });

	});


	$('.body-types img').on('click',function(){
		$('.body-types img').removeClass('selected');
		$(this).addClass('selected');
		var shape = $(this).attr('data-shape');
		$('.body-type-buttons input[data-shape='+shape+']').click();
		});
	var activePage = 1;
	
	$('.controls-container .next').on('click',function(){
	
		if(activePage !== 8){
		var goodtogo = true;
		if(activePage === 1){
			if($('input[name="address[address1]"]').val() === ''){
			console.log('address good');
			goodtogo = false;
			}

			if($('input[name="address[city]"]').val() === ''){
			goodtogo = false;
			}  
			if($('input[name="address[zipCode]"]').val() === ''){
			goodtogo = false;
			}      
			if($('input[name="fields[mainPhoneNumber]"]').val() === ''){
			goodtogo = false;
			}  
			if($('input[name="fields[preferredContactCall]"]').val() === ''){
			if($('input[name="fields[preferredContactText]"]').val() === ''){
				if($('input[name="fields[preferredContactEmail]"]').val() === ''){
				goodtogo = false;
				}     
			}     
			}  
										

		}      
		if(goodtogo === true){
			if($('#update-address input[name=sent]').val() === 'false'){
			$.ajax({
				type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
				url         : $('#update-address').attr('action'), // the url where we want to POST
				data        : $('#update-address').serialize(), // our data object,
				dataType		: 'json'
			})
			// using the done promise callback
			.done(function(data) {
				$('#update-address input[name=sent]').val('true');
				// log data to the console so we can see
				console.log(data); 
				// window.location.href = $('#create-profile').attr('data-return')
				// here we will handle errors and validation messages
			});
			}  
			$('.status-step .diamond').removeClass('active');
			$('.status-step .diamond').eq(activePage - 1).addClass('done');
			$('.status-step .diamond').eq(activePage).addClass('active');
			activePage = activePage + 1;
			if (activePage === 1){
			$('.controls-container .prev').addClass('inactive');
			} else {
			$('.controls-container .prev').removeClass('inactive');
			}        
	
			$('.create-profile-page').removeClass('active');
			$('.create-profile-page[data-page='+activePage+']').addClass('active');
			$('.status-bar.done').animate({width: (activePage-1)*100/8+'%'});
			var $adressFields = $('#update-address :input');
			var cp = $('#create-profile :input').not($adressFields).serialize();
			$.ajax({
				type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
				url         : 'actions/users/save-user', // the url where we want to POST
				data        : cp,
				dataType		: 'json' // our data object
			}).done(function(data) {
				
				// log data to the console so we can see
				console.log(data); 
				// here we will handle errors and validation messages
			});
		} else {
			console.log('not valid');
			$('p.validation').html('All fields on this page are required');
		}

		} else {
		
		var $adressFields = $('#update-address :input');
		var cp = $('#create-profile :input').not($adressFields).serialize();
		$.ajax({
			type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
			url         : 'actions/users/save-user', // the url where we want to POST
			data        : cp,
			dataType		: 'json' // our data object
		})
		// using the done promise callback
		.done(function(data) {
	
			// log data to the console so we can see
			console.log(data); 
			window.location.href = $('#create-profile').attr('data-return');
			// here we will handle errors and validation messages
		});
		}
	});

	$('.controls-container .prev').on('click',function(){
		if(activePage !== 1){
		activePage = activePage - 1;
		var $adressFields = $('#update-address :input');
		var cp = $('#create-profile :input').not($adressFields).serialize();

		$.ajax({
			type : 'POST', // define the type of HTTP verb we want to use (POST for our form)
			url  : 'actions/users/save-user', // the url where we want to POST
			data : cp,
			dataType		: 'json' // our data object
		})
		// using the done promise callback
		.done(function(data) {
	
			// log data to the console so we can see
			console.log(data); 
			// window.location.href = $('#create-profile').attr('data-return')
			// here we will handle errors and validation messages
		});
			$('.status-step .diamond').removeClass('active');
			$('.status-step .diamond').eq(activePage - 1).addClass('active').removeClass('done');
			if (activePage === 1){
				$('.controls-container .prev').addClass('inactive');
			} else{
				$('.controls-container .prev').removeClass('inactive');
			}

			$('.create-profile-page').removeClass('active');
			$('.create-profile-page[data-page='+activePage+']').addClass('active');
			$('.status-bar.done').animate({width: (activePage-1)*100/8+'%'});
		}
	});

	Dropzone.options.profileImage = {
		accept: function(file, done) {
			console.log('uploaded');
			done();
		},
		params: {

		},
		paramName: 'fields[profilePhoto]',
		addRemoveLinks: true,
		init: function() {
			this.on('addedfile', function() {
			if (this.files[1] !== null) {
				this.removeFile(this.files[0]);
			}
		});
		this.on('success', function(data) {
			console.log('success', data);
		});


		}
	};

};


