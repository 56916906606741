'use strict';

let $bodyModal = $('.body-modal');
let $closeModal = $('.close-modal');
let $modalTrigger = $('.modal-trigger');
let $figure = $('.figure');
let $figureInputs = $('.body-modal input');
let $bodySlick = $('.body-slick');

export default class Profile {
    constructor() {
        this.initSlick();
    }

    initSlick() {
        $bodySlick.slick({
            dots: false,
            infinite: true,
            arrows: false,
            slidesToScroll: 6,
            slidesToShow: 6,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true
                    }
                }
            ]
        });
    }

    eventListeners() {
        $modalTrigger.on('click', function(e) {
            e.preventDefault();
            $bodyModal.addClass('show-modal');
        });

        $figureInputs.on('click', function() {
            $figureInputs.prop('checked', '');
            $(this).prop('checked', 'checked');
        });

        $closeModal.on('click', function(e) {
            e.preventDefault();
            $bodyModal.removeClass('show-modal');
            let $figureValue = $('.body-modal input:checked').val();
            $figure.attr('src', '/svg/' + $figureValue + '.svg');
            $figure.addClass('show-figure');
        });

        $('.edit').on('click',function(){
            let editId = $(this).data('id');
            $(this).parent().siblings('.edit-address[data-id='+editId+']').toggleClass('active');
        });

        $('.add').on('click',function(){
            $(this).parent().siblings('.add-address').toggleClass('active');
        });
    }
}



