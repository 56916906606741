'use strict';

let $productImages   = $('.image-box');
let $relatedProducts = $('.related-slick');
let $addDressing     = $('.add-dressing');
let $addDressingSpan = $('.add-dressing span');
let $removeDressing  = $('.remove-dressing');
let $dressingHref    = $('.dressing-href');
let $toggleChart     = $('.sizing-chart');
let $sizeContainer   = $('.size-container');
let $closeSizing     = $('.close-sizing');
let $sizeSection     = $('.size ul');

let $complimentButton      = $('.show-related.compliment');
let $alternateButton       = $('.show-related.alternate');
let $closeComplimentButton = $('.close-items.compliment');
let $closeAlternateButton  = $('.close-items.alternate');
let $descriptionButton     = $('.product-description');
let $sizeButton = $('.size li');

export default class Product {
    constructor() {
        this.state = {};
        this.initlializePlugins();
    }

    showAlternateItems() {
        $('.related-products.alternate').addClass('show-related-products');
    }
    showComplimentItems() {
        $('.related-products.compliment').addClass('show-related-products');
    }
    hideAlternateItems() {
        $('.related-products.alternate').removeClass('show-related-products');
    }
    hideComplimentItems() {
        $('.related-products.compliment').removeClass('show-related-products');
    }
    showDescription() {
        $('.product-bullets').toggleClass('product-bullets-show');
    }

    changeSize(id) {
        $('.add-dressing').attr('data-product',id);
        $('input[name=purchasableId]').val(id);
    }

    outOfStock() {
        let $listElement  = $('<li>');
        let $notification = $('<p>');
        $notification.text('Out of stock!');
        $notification.addClass('notification-tag');
        $listElement.append($notification);
        $sizeSection.append($listElement);
        $notification.fadeOut(3000);
    }

    addToDressingRoom(productId,userId) {
        let self = this;
        $.ajax({
            url: '/actions/dressing-room/main/client-add-to-dressing-room',
            type: 'POST',
            dataType: 'JSON',
            data: {
                variantId: productId,
                userId: userId
            }
        })
        .done(function() {
            self.addNotification();
        })
        .fail(function(error) {
            console.log(error);
        });    
    }

    addNotification() {
        let $notification = $('<p>');
        $notification.text('Item Added!');
        $notification.addClass('notification-tag');
        $addDressingSpan.append($notification);
        $notification.fadeOut(3000);
    }

    removeFromDressingRoom(element) {
        let productId = element.data('product');
        let userId = element.data('user');
        let $parent = element.parents('li');
        $.ajax({
            url: '/actions/dressing-room/main/client-remove-from-dressing-room',
            type: 'POST',
            dataType: 'JSON',
            data: {
                
                variantId: productId,
                userId: userId
            }
        })
        .done(function() {
            $parent.fadeOut(300, function() {
                $parent.remove();
            });
        })
        .fail(function(error) {
            console.log(error);
        });
    }

    showSizingChart() {
        $sizeContainer.toggleClass('show-size');
    }

    eventListeners() {
        let Product = this;

        $complimentButton.on('click', () => {
            this.showComplimentItems();
        });

        $alternateButton.on('click', () => {
            this.showAlternateItems();
        });

        $closeComplimentButton.on('click', () => {
            this.hideComplimentItems();
        });

        $closeAlternateButton.on('click', () => {
            this.hideAlternateItems();
        });

        $sizeButton.on('click', function() {
            let $this = $(this);
    
            if ($this.hasClass('no-inventory')) {
                Product.outOfStock($this);
                return;
            }
    
            $sizeButton.removeClass('active');
            let $variantId = $this.data('variant');
            Product.changeSize($variantId);
            $this.addClass('active');
        });

        $descriptionButton.on('click', () => {
            this.showDescription();
        });

        $addDressing.on('click', function() {
            if ($(this).hasClass('no-user')) {
                $('.login-modal').removeClass('hide');
                return;
            }
            let productId = $(this).data('product');
            let userId = $(this).data('user');
            Product.addToDressingRoom(productId, userId);
        });

        $removeDressing.on('click', function(event) {
            event.stopPropagation();
            let $this = $(this);
            Product.removeFromDressingRoom($this);
        });

        $dressingHref.on('click', function() {
            let $href = $(this).data('href');
            window.location = $href;
        });

        $toggleChart.on('click', () => {
            this.showSizingChart();
        });

        $closeSizing.on('click', () => {
            this.showSizingChart();
        });
    }

    initlializePlugins() {
        $productImages.on('init', function() {
            let zoomTrigger = document.getElementsByClassName('product-image') || null;
            if (zoomTrigger) {
                for (let i=0; i < zoomTrigger.length; i++) {
                    new Drift(zoomTrigger[i], {
                        paneContainer: document.querySelector('.product-image-container'),
                        inlinePane: true,
                        inlineOffsetY: -5,
                        containInline: true,
                        sourceAttribute: 'data-zoom',
                        zoomFactor: 2.5,
                        handleTouch: true
                    }); 
                } 
            }
        }); 
        
        $productImages.slick({
            dots: true,
            lazyLoad: 'ondemand',
            arrows: true,
            swipe: false,
            slidesToShow: 1,
            speed: 500 
        });
        
        $relatedProducts.slick({
            dots: true,
            infinite: true,
            speed: 750,
            lazyLoad: 'ondemand',
            adaptiveHeight: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                  breakpoint:900,
                  settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
}

