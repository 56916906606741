'use strict';

var $sameAddress = $('input[name="billingAddressSameAsShipping"]');
var $addressToggle = $('input[name="addressToggle"]');
var $shipping = $('.shipping');
var $billing = $('.billing');
var $addressesBilling = $('.addresses-billing');

var $newShippingAddress = $('input[name="shippingAddressId"]');
var $newBillingAddress = $('input[name="billingAddressId"]');

var $addEmail = $('#add-email-button');
var $addEmailForm = $('#add-email');

export default class Checkout {
	constructor() {
		
	}

	eventListeners() {
		$addressToggle.on('change', function() {
			var $this = $(this);
			if ($this.is(':checked')) {
				$addressesBilling.addClass('hidden');
				$billing.addClass('hidden');
				$sameAddress.val('1');
			} else {
				$addressesBilling.removeClass('hidden');
				$sameAddress.val('0');
			}
		});
		$newShippingAddress.on('change', function() {
			var $this = $(this);
			if ($this.hasClass('newAddressId') && $this.is(':checked')) {
				$shipping.removeClass('hidden');
			} else {
				$shipping.addClass('hidden');
			}
		});
		$newBillingAddress.on('change', function() {
			var $this = $(this);
			if ($this.hasClass('newAddressId') && $this.is(':checked')) {
				$billing.removeClass('hidden');
			} else {
				$billing.addClass('hidden');
			}
		});
		$addEmail.on('click', function(e) {
			e.preventDefault();
			$addEmailForm.submit();
		});
	}
}


