'use strict';

let $eachLook = $('.each-look');
let $lookbookModal = $('.looks-modal');
let $filter = $('.filter');
// let $lookTitle = $('.filter h2');

export default class Lookbook {
    constructor() {
        let { pathname } = document.location;
        if (pathname === '/lookbook') {
            this.checkForLook();
        }
    }

    eventListeners() {
        let Lookbook = this;
        $eachLook.on('click', function() {
            let $this = $(this);
            if ($this.hasClass('preview')) {
                return;
            }
            let $id = $this.data('id');
            // let $title  = $this.data('name');
            Lookbook.getRelatedItems($id);
        });

        $('body').on('click', '.close-items', () => {
            this.clearModal();
        });
    }

    getRelatedItems(id, reload) {
        let $this = this;
        $.ajax({
            url: '/api/lookbook/' + id,
            dataType: 'html',
            success: function(html) {
                $this.insertProducts(html);
                if (!reload) {
                    $this.addLookParam(id);
                }
            }
        });
    }

    checkForLook() {
        let uri = new Uri(window.location);
        if (uri.hasQueryParam('lookId')) {
            let lookId = uri.getQueryParamValue('lookId');
            this.getRelatedItems(lookId, true);
        }
    }

    insertProducts(html) {
        let $html = $.parseHTML(html);
        $lookbookModal.append($html);
        $('.slick-lookbook').on('init', function() {
            $filter.addClass('show-lookbook-modal');
        });
        this.initializeCarousel();
    }

    addLookParam(id) {
        let uri = new Uri(window.location);
        uri.addQueryParam('lookId', id);
        history.pushState({},'', uri);
    }

    removeLookParam() {
        let uri = new Uri(window.location);
        uri.deleteQueryParam('lookId');
        history.pushState({},'', uri);
    }

    clearModal() {
        $('.slick-lookbook').remove();
        $('.looks-modal h2').remove();
        $filter.removeClass('show-lookbook-modal');
        this.removeLookParam();
    }

    initializeCarousel() {
        $('.slick-lookbook').slick({
            dots: true,
            infinite: true,
            speed: 750,
            adaptiveHeight: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                  breakpoint:900,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        });
    }
}


