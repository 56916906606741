'use strict';

import { $_GET } from './utils';

let $productContainer = $('.product-container-scroll');
let $loaderContainer  = $('.loader-container');
let $explainModal     = $('.explain-container');
let $applyButton      = $('.apply');
let $clearButton      = $('.clear');
let $filterToggle     = $('.filter-bar button.cta');


export default class Filter {
    constructor() {
        this.state = {
            blockAjax: false,
            page: 2
        };
        this.checkUrlOnLoad();

        this.appendProducts = this.appendProducts.bind(this);
        this.getProducts = this.getProducts.bind(this);
    }

    init() {
        if (window.location.pathname === '/products') {
            window.onpopstate = function() {
                this.getProducts();
            };
            this.checkForFilters(); // check for current filter queries on page load
            this.onWindowScroll();
        } 
    }

    /**
     * Fires immediately when class is instantiated
     * 
     * @memberof Filter
     */
    checkUrlOnLoad() {
        let prevUrl = new Uri(window.location);
        let prevPath = prevUrl.path();
        let query = prevUrl.query();
        if (prevPath === '/products' && query !== '') {
            $explainModal.addClass('hide-explain');
            this.getProducts();
        }
    }

    /**
     * Clears params from query and removes
     * 
     */
    clearParams() {
        // let $allProducts = $('.product-container-scroll').find('li');
        // $allProducts.remove();
        this.clearProducts();
        let uri = new Uri(window.location);
        uri.deleteQueryParam('colors');
        uri.deleteQueryParam('categories');
        uri.deleteQueryParam('designers');
        uri.deleteQueryParam('sizes');
        uri.deleteQueryParam('materials');
        history.pushState({},'', uri);
    }

    /**
     * Adds a query param to url
     * 
     * @param {string} key 
     * @param {string} id 
     * @memberof Filter
     */
    addParam(key, id) {
        let uri = new Uri(window.location);
        uri.addQueryParam(key, id);
        history.pushState({},'', uri);
    }

    /**
     * Removes a query param from url
     * 
     * @param {string} key 
     * @param {string} id 
     * @memberof Filter
     */
    removeParam(key, id) {
        let uri = new Uri(window.location);
        id = id.toString();
        uri.deleteQueryParam(key, id);
        history.pushState({},'', uri);
    }

    /**
     * Constructs query based off of current url query params
     * 
     * @returns {object} query object
     */
    constructQuery() {
        let categories = $_GET('categories');
        let colors = $_GET('colors');
        let designers = $_GET('designers');
        let sizes = $_GET('sizes');
        let materials = $_GET('materials');
    
        // Construct query object to pass to AJAX request
        let query = {};
        if (categories) {
            query.categories = categories;
        }
        if (colors) {
            query.colors = colors;
        }
        if (designers) {
            query.designers = designers;
        }
        if (sizes) {
            query.sizes = sizes;
        }
        if (materials) {
            query.materials = materials;
        }
        return query;
    }

    /**
     * Displays loading icon and triggers call to
     * function to make the AJAX request to server
     * 
     */
    getProducts() {
        if (window.location.pathname !== '/products') {
            let query = window.location.search;
            window.location.href = '/products' + query;
            return;
        }
        $loaderContainer.addClass('show-loader');

        $('.main-nav').removeClass('nav-open');
        $('.mobile-menu').removeClass('menu-open');
        $('.desktop-nav ul.sub-nav').removeClass('expand');
        this.closeFilterModule();

        this.getProductsAJAX('', (html) => {
            this.clearProducts();
            this.appendProducts(html);
        });
    }

    /** 
     * Clears products from DOM and resets scroll pos
     * to top of document and reset pagination index
     * to the base, 2
     * 
    */
    clearProducts() {
        $productContainer.find('li').remove();
        let $noMatchMsg = $('.no-match-message');
        let $noMorePages = $('.no-more-pages');
        $noMatchMsg.add($noMorePages)
                   .remove();
        this.state.page = 2;
        $('html, body').scrollTop(0);
    }

    /**
     * Appends new products to DOM
     * 
     * @param {html} html 
     */
    appendProducts(html) {
        $productContainer.removeClass('all');
        let $html = $.parseHTML(html);
        $productContainer.append($html);

        $loaderContainer.removeClass('show-loader');

        $('.main-nav').removeClass('nav-open');
        $('.mobile-menu').removeClass('menu-open');
        $('.desktop-nav ul.sub-nav').removeClass('expand');

        this.state.blockAjax = false; // allow more requests
        // RE INIT LAZY LOAD ON IMAGES
        $(window).lazyLoadXT();
    }

    /**
     * Returns html markup for a filter box
     * 
     * @param {string} filterID 
     * @param {string} type 
     * @param {string} name 
     * @param {boolean} [clear=false] 
     * @returns filter box html markup
     * @memberof Filter
     */
    filterTagHTML(filterID, type, name, clear = false) {
        return `<li class="filter-box filter-active current-filter ${clear ? 'clear-filters ':''}" 
                    data-id="${filterID}" 
                    data-type="${type}">
                        <a to="#">${name}</a>
                </li>`;
    }

    /**
     * Checks URL for current filters and applies active class
     * to those menu items
     * 
     */
    checkForFilters() {
        let categories = $_GET('categories').split(',');
        let colors     = $_GET('colors').split(',');
        let designers  = $_GET('designers').split(',');
        let sizes      = $_GET('sizes').split(',');
        let materials  = $_GET('materials').split(',');
        if(categories[0] === '' && colors[0] === '' && designers[0] === '' && sizes[0] === '' && materials[0] === '') { return; }

        this.applyFilters({categories, colors, designers, sizes, materials});

    }

    /**
     * adds currently selected filters to filter bar and
     * styles them in filter module
     * 
     * @param {object} params 
     */
    applyFilters(params) {
        let { categories, colors, designers, sizes, materials } = params;
        let filters = [...categories, ...colors, ...designers, ...sizes, ...materials].filter(f => f !== '');
        let filterTypes = [[categories, 'categories'], [colors, 'colors'], [designers, 'designers'], [sizes, 'sizes'], [materials, 'materials']];

        $('.mobile-nav li').removeClass('filter-active');
        $('.current-filters-list').empty();

        let filterArray = [];
        filters.forEach((filterID) => {
            let type = idArray[`_${filterID}`].type; // grab filter type from data-id
            let name = idArray[`_${filterID}`].name; // grab filter name from data-id
            let $filterItem = $('.filter-list li[data-id="' + filterID + '"]');
            $filterItem.addClass('filter-active'); // add style to filter module filter-item
            filterArray.push(this.filterTagHTML(filterID, type, name));
        });
        // Add CLEAR btn
        filterArray.push(this.filterTagHTML(-1, 'Clear', 'Clear', true));
        $('.current-filters-list').html(filterArray.join(''));

        filterTypes.forEach(type => {
            if(type[0] !== '') {
                $(`.${type[1]}`).addClass('expand')
                                .siblings('.filter-title')
                                .addClass('flip');
            }
        });
    }

    /**
     * Forms query object and makes call
     * to server for products
     * 
     * @param {number} [page=''] -- page to query items for
     * @param {callback} [callback=Filter.appendProducts] -- callback to handle items
     */
    getProductsAJAX(page = '', callback = this.appendProducts) {
        let query = this.constructQuery();
        $.ajax({
            url: `/api/filter${page}`,
            dataType: 'html',
            data: query,
            success: (html) => {
                callback(html);
                this.checkForFilters();
            }
        });
    }

    /**
     * Checks if user is scrolled to bottom
     * and makes additional API calls for more products
     * 
     */
    getMoreProduct() {
        // get distance from bottom of product container to top of document
        // subtract window.scrollTop and window.height 
        let $container = $('.product-container-scroll');
        let height = $container.offset().top + $container.height();
        let distFromBottomOfContainer = height - $(window).scrollTop() - $(window).height();
        if(distFromBottomOfContainer <= 800 && !this.state.blockAjax) {
            // make AJAX call for more product
            this.getProductsAJAX(`/p${this.state.page++}`);
            this.state.blockAjax = true;
        }
    }

    /**
     * 
     * 
     */
    onWindowScroll() {
        $(window).on('scroll', () => {
            if(!$('.no-match-message').exists() && !$('.no-more-pages').exists()) {
                this.getMoreProduct();
            }
        });
    }

    /**
     * Opens filter module menu
     * 
     */
    openFilterModule() {
        $('.filter-module').addClass('reveal');
        $('body').addClass('lock');
    }

    /**
     * Closes filter module menu
     * 
     */
    closeFilterModule() {
        $('.filter-module').removeClass('reveal');
        $('body').removeClass('lock');
    }

    eventListeners() {

        let $filterTitle = $('.filter-title');
        let $filterListBox = $('.filter-list li');
        let $expandCloseAllBtns = $('.expand-close-all-btns button');
        let $currentFiltersList = $('.current-filters-list');
        let $backBtn = $('.back-btn');
    
        let Filter = this;

        $filterTitle.on('click', function() {
            let $filterList = $(this).siblings('.filter-list');
            if(window.innerWidth < 900) {
                $filterList.toggleClass('expand');
                $(this).toggleClass('flip'); 
            } else {
                $filterList.removeClass('expand');
                $(this).removeClass('flip'); 
            }
        }); 
    
        // Removes current filters from filter bar and filter module on click
        $currentFiltersList.on('click', 'li', (e) => {
            let { id, type } = e.target.dataset;
            this.removeParam(type, id);
            if(type === 'Clear') {
                this.clearParams();
                $('.current-filter').remove(); // removes from filter bar
                $('.filter-active').removeClass('filter-active'); // removes all active filter styling from filter module
            } else {
                $(e.target).remove(); // removes from filter bar
                $('.filter-list li[data-id="' + id + '"]').removeClass('filter-active'); // removes a filters active-filter style from filter-module
            }
            if($('.current-filters-list li').length > 1) {
                this.getProducts(); // at least one filter is still active, get updated products
            } else {
                this.openFilterModule(); // no more filters, open filter module
            }
        });
    
        $expandCloseAllBtns.on('click', function() {
            if($(this).hasClass('expand-all-btn')) {
                $('.filter-list').addClass('expand');
                $('.filter-title').addClass('flip');
            } else {
                $('.filter-list').removeClass('expand');
                $('.filter-title').removeClass('flip');
            }
        });
    
        $productContainer.on('click', '.no-match-message', () => {
            this.openFilterModule();
        });
    
        $filterListBox.on('click', function(e) {
            e.preventDefault();
            let $this = $(this);
            if ($this.hasClass('color-swatch')) {
                return;
            }
            let $id = $this.data('id');
            let type = idArray[`_${$id}`].type;
            if ($this.hasClass('filter-active')) {
                $this.toggleClass('filter-active');
                Filter.removeParam(type, $id);
                return;
            }
            $this.addClass('filter-active');
            Filter.addParam(type, $id);
        });
    
        $filterToggle.on('click', () => {
            this.openFilterModule();
        });
    
        $backBtn.on('click', () => {
            this.closeFilterModule();
        });
    
        $applyButton.on('click', () => {
            this.getProducts();
        });
    
        $clearButton.on('click', () => {
            this.clearParams();
            $('.filter-active').removeClass('filter-active');
        });
    }
}


